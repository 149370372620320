<template>
	<div class="page-container">
		<div class="card" style="width: 400px;">
			<h5 class="">Login</h5>
			<div class="alert alert-danger alert-dismissable" v-if="showErrorAlert">
				Invalid Login Details
			</div>
			<div class="card-body">
				<form @submit.prevent="onSubmit" novalidate>
					<div class="form-group mb-2">
						<label for="">Email</label>
						<input type="email" v-model="vv.email.$model" placeholder="Enter email address" class="form-control">
						<span class="error">{{ vv?.email?.$errors[0]?.$message }}</span>
					</div>
					<div class="form-group mb-3">
						<label for="">Password</label>
						<input type="password" v-model="vv.password.$model" placeholder="Enter password" class="form-control">
						<span class="error">{{ vv?.password?.$errors[0]?.$message }}</span>
					</div>
					<div class="d-grid gap-2">
						<button class="btn btn-primary btn-block">Login</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { authService } from '@/services/auth.service.js'
export default {

	name: 'Login',
	setup() {
		const fform = reactive({
			email: '',
			password: ''
		})
		const rules = {
			email: { required, email },
			password: { required }
		}
		const vv = useVuelidate(rules, fform);
		return {
			vv,
			fform
		}
	},
	data() {
		return {
			courseList: [],
			showErrorAlert: false
		};
	},
	methods: {
		onSubmit () {
			this.vv.$touch();
			if (this.vv.$invalid) return;
			const postData = {
				email: this.fform.email,
				password: this.fform.password
			}
			authService.processLogin(postData)
				.then((response) => {
					if(parseInt(response.data.message_code) === 200){
						authService.saveToken(response.data)
						this.$router.push('/panel')
					}else{
						this.$swal({
							icon: 'error',
							text: 'Invalid Login Details'
						})
					}
				})
				.catch((e) => {
					console.log(e)
					this.$swal({
						icon: 'error',
						text: 'An error occured. Please try again later'
					})
				})
		}
	}
};
</script>

<style scoped>
.page-container{
	background: var(--paul-color-light);
	width: 100vw;
	height: 100vh;
	padding-top: 100px;
}
.card{
	margin: auto;
	padding-bottom: 10px;
	background: transparent;
	border: none;
}
.card-body{
	padding-top: 5px !important;
}
label{
	font-weight: 550;
	font-size: 14px;
	margin-bottom: 5px;
}
h5{
	margin-left: 15px;
	font-size: 25px;
	font-weight: 750;
	margin-bottom: 5px;
	text-transform: uppercase;
	text-align: center;
	/*color: var(--paul-color-lfx-new) !important;*/
}
.btn{
	background: var(--paul-color-lfx-new) !important;
	border-color: var(--paul-color-lfx-new) !important;
}
.alert{
	margin: 15px;
	padding: 10px 7px;
}
</style>
