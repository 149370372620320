import axios from 'axios'
import { storageService } from './storage.service'

const baseUrl = storageService.setApiUrl()

class AuthService {
	apiUrl = baseUrl
	async processLogin (postData) {
		return await axios.post(`${baseUrl}/login`, postData)
	}

	saveToken (tokenData) {
		storageService.storeItem(tokenData)
	}

	logOut () {
		storageService.removeItem('huslinq_panel')
	}
}
const authService = new AuthService()
export { authService }